<template>
  <base-icon-button
    :icon="mdiThemeLightDark"
    :tooltip-translation="
      $t($vuetify.theme.dark ? 'profile.light_mode' : 'profile.dark_mode')
    "
    @click="save"
  />
</template>

<script>
import BasicMixin from "../../../mixins/BasicMixin";
import BaseIconButton from "./BaseIconButton";
import { mdiThemeLightDark } from "@mdi/js";

export default {
  name: "ThemeSwitch",
  components: { BaseIconButton },
  mixins: [BasicMixin],
  data() {
    return {
      mdiThemeLightDark,
      personalization: {},
    };
  },
  created() {
    this.personalization =
      this.$store.getters["basic_module/get_personalization"];
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = this.personalization.dark_mode;
      if (this.$route.fullPath === "/diagnostics") {
        window.frames[0].postMessage(
          {
            dark_theme: this.personalization.dark_mode,
          },
          "*"
        );
      }
    },
    save() {
      this.personalization.dark_mode = !this.personalization.dark_mode;
      const config = {
        method: "patch",
        message: { success: true, action: "update", model: "profile" },
        url: "profile/update_preferences",
        after: this.after,
      };
      this.actionsMixin_action(config, { settings: this.personalization });
    },
    after() {
      this.$store.commit(
        "basic_module/set_personalization",
        this.personalization
      );
      this.changeTheme();
    },
  },
};
</script>

<style scoped></style>
