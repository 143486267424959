import Vue from "vue";
import VueRouter from "vue-router";
import qs from "qs";

import store from "../store";
import BasicMixin from "../../_generic/mixins/BasicMixin";

// Base Components
import AppBar from "../../_generic/pages/AppBar";
import LoadingScreen from "../../_generic/pages/LoadingScreen";
import BaseView from "../../_generic/pages/components/base/BaseView";
import Document from "../../_generic/classes/Document";
import Contract from "../../_generic/classes/Contract";
import Service from "../../_generic/classes/Service";
import Rule from "../../_generic/classes/Rule";
import Kpi from "../../_generic/classes/Kpi";
import Warehouse from "../../_generic/classes/Warehouse";
import PriceList from "../../_generic/classes/PriceList";
import Connector from "../../_generic/classes/Connector";
import Mapper from "../../_generic/classes/Mapper";
import Snippet from "../../_generic/classes/Snippet";
import PriceItem from "../../_generic/classes/PriceItem";
import DataSet from "../../_generic/classes/DataSet";
import Activity from "../../_generic/classes/Activity";
import Role from "../../_generic/classes/Role";
import ExternalEmail from "../../_generic/classes/ExternalEmail";
import User from "../../_generic/classes/User";
import AutomatedAction from "../../_generic/classes/AutomatedAction";
import Report from "../../_generic/classes/Report";
import Person from "../../_generic/classes/Person";
import Asset from "../../_generic/classes/Asset";
import Issue from "../../_generic/classes/Issue";
import AssetCategory from "../../_generic/classes/AssetCategory";
import PersonCategory from "../../_generic/classes/PersonCategory";
import Translation from "../../_generic/classes/Translation";
import TagDefinition from "../../_generic/classes/TagDefinition";
import Reminder from "../../_generic/classes/Reminder";
import Notification from "../../_generic/classes/Notification";
import Subscription from "../../_generic/classes/Subscription";
import PersonRelation from "../../_generic/classes/PersonRelation";
import Holiday from "../../_generic/classes/Holiday";
import Device from "../../_generic/classes/Device";
import IssueState from "../../_generic/classes/IssueState";
import IssuePriority from "../../_generic/classes/IssuePriority";
import Vulnerability from "../../_generic/classes/Vulnerability";
import Threat from "../../_generic/classes/Threat";
import Control from "../../_generic/classes/Control";
import Consequence from "../../_generic/classes/Consequence";
import Likelihood from "../../_generic/classes/Likelihood";
import RiskLevel from "../../_generic/classes/RiskLevel";
import Audit from "../../_generic/classes/Audit";
import Standard from "../../_generic/classes/Standard";
import BackgroundJob from "../../_generic/classes/BackgroundJob";
import Soa from "../../_generic/classes/Soa";
import StandardSoa from "../../_generic/classes/StandardSoa";
import Field from "../../_generic/classes/Field";
import AssetGroup from "../../_generic/classes/AssetGroup";
import PrimaryAsset from "../../_generic/classes/PrimaryAsset";
import Risk from "../../_generic/classes/Risk";
import AppliedControl from "../../_generic/classes/AppliedControl";

// ---------------------- Public ----------------------
const PublicScreen = () =>
  import(/* webpackChunkName: "public" */ "../../_generic/pages/PublicScreen");

const LoginWrapper = () =>
  import(
    /* webpackChunkName: "public" */ "../../_generic/pages/LoginWrapper.vue"
  );

const LoginForm = () =>
  import(
    /* webpackChunkName: "public" */ "../../_generic/pages/public_forms/LoginForm.vue"
  );

const SsoForm = () =>
  import(
    /* webpackChunkName: "public" */ "../../_generic/pages/public_forms/SsoForm.vue"
  );

const PasswordRecoveryForm = () =>
  import(
    /* webpackChunkName: "public" */ "../../_generic/pages/public_forms/PasswordRecoveryForm.vue"
  );

const PasswordResetForm = () =>
  import(
    /* webpackChunkName: "public" */ "../../_generic/pages/public_forms/PasswordResetForm.vue"
  );

const PublicIssuesShow = () =>
  import(
    /* webpackChunkName: "public" */ "../../_functions/pages/public_issues/PublicIssuesShow"
  );

const PublicDocumentsShow = () =>
  import(
    /* webpackChunkName: "public" */ "../../_functions/pages/public_documents/PublicDocumentsShow"
  );

const PublicIssuesNew = () =>
  import(
    /* webpackChunkName: "public" */ "../../_functions/pages/public_issues/PublicIssuesNew"
  );

const PublicIssuesMap = () =>
  import(
    /* webpackChunkName: "public" */ "../../_functions/pages/public_issues/PublicIssuesMap"
  );

// ---------------------- Profile ----------------------
const AccountSettings = () =>
  import(
    /* webpackChunkName: "profile" */ "../../_generic/pages/profile/AccountSettings"
  );

const SettingsEdit = () =>
  import(
    /* webpackChunkName: "profile" */ "../../_functions/pages/users/profile_components/SettingsEdit"
  );

const AboutPage = () =>
  import(
    /* webpackChunkName: "profile" */ "../../_functions/pages/users/profile_components/AboutPage"
  );

// ---------------------- Inner ----------------------

// Main Parts

const BaseShow = () => import("../../_generic/pages/components/base/BaseShow");

const MultiOperations = () =>
  import("../../_generic/pages/components/base/MultiOperations");

const MaintenancePlanning = () =>
  import(
    /* webpackChunkName: "issues" */ "../../_functions/pages/calendar_persons/MaintenancePlanning"
  );

const AssetsCalendar = () =>
  import(
    /* webpackChunkName: "services" */ "../../_functions/pages/calendar_assets/AssetsCalendar"
  );

const RiskTable = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/risks/RiskTable.vue"
  );

const ControlsManagementTable = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/controls_management/ControlsManagementTable.vue"
  );

const LinkagesSettingTable = () =>
  import(
    /* webpackChunkName: "risks" */ "../../_functions/pages/linkages_settings/LinkagesSettingTable.vue"
  );

const StandbyPlan = () =>
  import(
    /* webpackChunkName: "services" */ "../../_functions/pages/services/components/StandbyPlan"
  );

const DiagnosticsWrapper = () =>
  import(
    /* webpackChunkName: "assets" */ "../../_functions/pages/diagnostic/DiagnosticsWrapper.vue"
  );

const DiagnosticsDevices = () =>
  import(
    /* webpackChunkName: "assets" */ "../../_functions/pages/diagnostic/DiagnosticsDevices.vue"
  );

const DiagnosticsDeviceDetail = () =>
  import(
    /* webpackChunkName: "assets" */ "../../_functions/pages/diagnostic/DiagnosticsDeviceDetail.vue"
  );

const DiagnosticsAlarms = () =>
  import(
    /* webpackChunkName: "assets" */ "../../_functions/pages/diagnostic/DiagnosticsAlarms.vue"
  );

const DiagnosticsLogs = () =>
  import(
    /* webpackChunkName: "assets" */ "../../_functions/pages/diagnostic/DiagnosticsLogs.vue"
  );

const DashboardScreens = () =>
  import(
    /* webpackChunkName: "dashboards" */ "../../_functions/pages/dashboards/visualization/DashboardScreen"
  );

const DashboardScreenView = () =>
  import(
    /* webpackChunkName: "dashboards" */ "../../_functions/pages/dashboards/visualization/DashboardScreenView"
  );

// ---------------------- Settings ----------------------
const PermissionsPersonsTree = () =>
  import(
    /* webpackChunkName: "persons" */ "../../_functions/pages/permissions/PermissonsPersonsTree"
  );

const LicensePage = () =>
  import(
    /* webpackChunkName: "profile" */ "../../_functions/pages/license/LicensePage"
  );

// ---------------------- Implementations ----------------------
const ImplementationAdministration = () =>
  import(
    /* webpackChunkName: "implementation" */
    "../../_functions/pages/implementation/components/ImplementationAdministration"
  );

const ImplementationNotice = () =>
  import(
    /* webpackChunkName: "implementation" */
    "../../_functions/pages/implementation/components/ImplementationNotice"
  );

const ImplementationHealthCheck = () =>
  import(
    /* webpackChunkName: "implementation" */
    "../../_functions/pages/implementation/components/ImplementationHealthCheck"
  );

const ImplementationStatistics = () =>
  import(
    /* webpackChunkName: "implementation" */
    "../../_functions/pages/implementation/components/ImplementationStatistics"
  );

const ImplementationSmtp = () =>
  import(
    /* webpackChunkName: "implementation" */ "../../_functions/pages/implementation/components/ImplementationSmtp"
  );

const ImplementationDocuments = () =>
  import(
    /* webpackChunkName: "implementation" */
    "../../_functions/pages/implementation/components/ImplementationDocuments"
  );

const ImplementationDefaultUserSettings = () =>
  import(
    /* webpackChunkName: "implementation" */
    "../../_functions/pages/implementation/components/ImplementationDefaultUserSettings"
  );

const ImplementationPublicForm = () =>
  import(
    /* webpackChunkName: "implementation" */
    "../../_functions/pages/implementation/components/ImplementationPublicForm"
  );

// ---------------------- Import/Export ----------------------
const ListImport = () =>
  import(
    /* webpackChunkName: "import" */ "../../_functions/pages/import/components/ListImport"
  );

const ImportDocuments = () =>
  import(
    /* webpackChunkName: "import" */ "../../_functions/pages/import/components/DocumentsImport"
  );

const ImportJSON = () =>
  import(
    /* webpackChunkName: "import" */ "../../_functions/pages/import/components/ImportJSON"
  );

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (store.state.app_state.auth) {
    if (store.state.basic_module.loaded) {
      const parts = to.path.split("/");
      let target = parts[2] === "import" ? `import_${parts[1]}` : parts[1];

      if (BasicMixin.methods.canSeeAppPart(target)) next();
      else {
        // want to access restricted application part, but don't have permission
        BasicMixin.methods.messageCustom("messages.appPartNoAccess", "error");
        next("/about");
      }
    } else {
      store.state.basic_module.loaded_next = to.path;
      store.state.basic_module.loaded_next_query = to.query;

      next("/loading");
    }
  } else {
    // go to login page if not logged in
    store.state.basic_module.loaded_next = to.path;
    store.state.basic_module.loaded_next_query = to.query;

    next("/public/auth/login");
  }
};

const ifAadEnabled = (to, from, next) => {
  if (store.state.app_state.auth) next("/");
  else if (store.state.basic_module.settings.aad) next("/public/auth/sso");
  else next();
};

const router = new VueRouter({
  routes: [
    {
      path: "/",
      name: "AppBar",
      component: AppBar,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: "persons/import",
          component: ListImport,
          props: { model: "person" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "persons",
          component: BaseView,
          props: { klass: Person },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Person },
              beforeEnter: ifAuthenticated,
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Person },
            },
          ],
        },
        {
          path: "assets/import",
          component: ListImport,
          props: { model: "asset" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "assets",
          component: BaseView,
          props: { klass: Asset },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Asset },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Asset },
            },
          ],
        },
        {
          path: "documents/import",
          component: ImportDocuments,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "documents",
          component: BaseView,
          props: { klass: Document },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Document },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Document },
            },
          ],
        },
        {
          path: "contracts/import",
          component: ListImport,
          props: { model: "contract" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "contracts",
          component: BaseView,
          props: { klass: Contract },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Contract },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Contract },
            },
          ],
        },
        {
          path: "services/import",
          component: ImportJSON,
          props: { model: "service" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "services",
          component: BaseView,
          props: { klass: Service },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Service },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Service },
            },
            {
              path: ":id/templates",
              component: BaseShow,
              props: { klass: Service },
            },
          ],
        },
        {
          path: "issues/import",
          component: ListImport,
          props: { model: "issue" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "issues",
          component: BaseView,
          props: { klass: Issue },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Issue },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Issue },
            },
            {
              path: ":id/notes",
              component: BaseShow,
              props: { klass: Issue },
            },
            {
              path: ":id/notes/:note_id",
              component: BaseShow,
              props: { klass: Issue },
            },
            {
              path: ":id/assets_issues",
              component: BaseShow,
              props: { klass: Issue },
            },
          ],
        },
        {
          path: "kpis",
          component: BaseView,
          props: { klass: Kpi },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Kpi },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Kpi },
            },
          ],
        },
        {
          path: "warehouses/import",
          component: ListImport,
          props: {
            model: "price_items_warehouse",
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "warehouses",
          component: BaseView,
          props: { klass: Warehouse },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Warehouse },
            },
          ],
        },
        {
          path: "price_lists/import",
          component: ListImport,
          props: {
            model: "price_items_price_list",
          },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "price_lists",
          component: BaseView,
          props: { klass: PriceList },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: PriceList },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: PriceList },
            },
          ],
        },
        {
          path: "rules/import",
          component: ListImport,
          props: { model: "rule" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "rules",
          component: BaseView,
          props: { klass: Rule },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Rule },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Rule },
            },
          ],
        },
        {
          path: "planning_calendar",
          component: MaintenancePlanning,
          props: { klass: Issue },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "maintenance_plan",
          component: AssetsCalendar,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "standby_plan",
          component: StandbyPlan,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "diagnostics",
          component: DiagnosticsWrapper,
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "devices",
              component: DiagnosticsDevices,
              children: [
                {
                  path: ":id",
                  component: DiagnosticsDeviceDetail,
                },
              ],
            },
            {
              path: "alarms",
              component: DiagnosticsAlarms,
            },
            {
              path: "logs",
              component: DiagnosticsLogs,
            },
          ],
        },
        {
          path: "connectors",
          component: BaseView,
          props: { klass: Connector },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Connector },
            },
          ],
        },
        {
          path: "mappers",
          component: BaseView,
          props: { klass: Mapper },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Mapper },
            },
          ],
        },
        {
          path: "external_emails",
          component: BaseView,
          props: { klass: ExternalEmail },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: ExternalEmail },
            },
          ],
        },
        {
          path: "permissions",
          component: PermissionsPersonsTree,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "roles",
          component: BaseView,
          props: { klass: Role },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Role },
            },
          ],
        },
        {
          path: "users/import",
          component: ListImport,
          props: { model: "user" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "users",
          component: BaseView,
          props: { klass: User },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: User },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: User },
            },
          ],
        },
        {
          path: "asset_categories",
          name: "asset_categories",
          component: BaseView,
          props: { klass: AssetCategory },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: AssetCategory },
            },
            {
              path: ":id/templates",
              component: BaseShow,
              props: { klass: AssetCategory },
            },
          ],
        },
        {
          path: "person_categories",
          name: "person_categories",
          component: BaseView,
          props: { klass: PersonCategory },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: PersonCategory },
            },
            {
              path: ":id/templates",
              component: BaseShow,
              props: { klass: PersonCategory },
            },
          ],
        },
        {
          path: "fields/import",
          component: ImportJSON,
          props: { model: "field" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "fields",
          component: BaseView,
          props: { klass: Field },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Field },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Field },
            },
          ],
        },
        {
          path: "price_items/import",
          component: ListImport,
          props: { model: "price_item" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "price_items",
          component: BaseView,
          props: { klass: PriceItem },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: PriceItem },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: PriceItem },
            },
          ],
        },
        {
          path: "issue_priorities/import",
          component: ListImport,
          props: { model: "issue_priority" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "issue_priorities",
          component: BaseView,
          props: { klass: IssuePriority },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: IssuePriority },
            },
          ],
        },
        {
          path: "issue_states/import",
          component: ListImport,
          props: { model: "issue_state" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "issue_states",
          component: BaseView,
          props: { klass: IssueState },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: IssueState },
            },
          ],
        },
        {
          path: "person_relations/import",
          component: ListImport,
          props: { model: "person_relation" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "person_relations",
          component: BaseView,
          props: { klass: PersonRelation },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: PersonRelation },
            },
          ],
        },
        {
          path: "activities",
          component: BaseView,
          props: { klass: Activity },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Activity },
            },
          ],
        },
        {
          path: "reports",
          component: BaseView,
          props: { klass: Report },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Report },
            },
          ],
        },
        {
          path: "automated_actions",
          component: BaseView,
          props: { klass: AutomatedAction },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: AutomatedAction },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: AutomatedAction },
            },
          ],
        },
        {
          path: "tag_definitions/import",
          component: ListImport,
          props: { model: "tag_definition" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "tag_definitions",
          component: BaseView,
          props: { klass: TagDefinition },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: TagDefinition },
            },
          ],
        },
        {
          path: "translations/import",
          component: ListImport,
          props: { model: "translation" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "translations",
          component: BaseView,
          props: { klass: Translation },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Translation },
            },
          ],
        },
        {
          path: "data_sets",
          component: BaseView,
          props: { klass: DataSet },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: DataSet },
            },
          ],
        },
        {
          path: "snippets",
          component: BaseView,
          props: { klass: Snippet },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Snippet },
            },
          ],
        },
        {
          path: "reminders",
          component: BaseView,
          props: { klass: Reminder },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "notifications",
          component: BaseView,
          props: { klass: Notification },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "risks",
          component: RiskTable,
          props: { klass: Risk },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "primary_assets",
          component: BaseView,
          props: { klass: PrimaryAsset },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: PrimaryAsset },
            },
          ],
        },
        {
          path: "asset_groups",
          component: BaseView,
          props: { klass: AssetGroup },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: AssetGroup },
            },
          ],
        },
        {
          path: "controls_management",
          component: ControlsManagementTable,
          props: { klass: AppliedControl },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "linkages_setting",
          component: LinkagesSettingTable,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "soas",
          component: BaseView,
          props: { klass: Soa },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Soa },
            },
          ],
        },
        {
          path: "threats/import",
          component: ListImport,
          props: { model: "threat" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "threats",
          component: BaseView,
          props: { klass: Threat },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Threat },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Threat },
            },
          ],
        },
        {
          path: "vulnerabilities/import",
          component: ListImport,
          props: { model: "vulnerability" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "vulnerabilities",
          component: BaseView,
          props: { klass: Vulnerability },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Vulnerability },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Vulnerability },
            },
          ],
        },
        {
          path: "controls/import",
          component: ListImport,
          props: { model: "control" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "controls",
          component: BaseView,
          props: { klass: Control },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Control },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Control },
            },
          ],
        },
        {
          path: "consequences",
          component: BaseView,
          props: { klass: Consequence },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "likelihoods",
          component: BaseView,
          props: { klass: Likelihood },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "risk_levels",
          component: BaseView,
          props: { klass: RiskLevel },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "audits",
          component: BaseView,
          props: { klass: Audit },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Audit },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Audit },
            },
          ],
        },
        {
          path: "standard_soas",
          component: BaseView,
          props: { klass: StandardSoa },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: StandardSoa },
            },
          ],
        },
        {
          path: "standards/import",
          component: ImportJSON,
          props: { model: "standard" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "standards",
          component: BaseView,
          props: { klass: Standard },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: "operations",
              component: MultiOperations,
              props: { klass: Standard },
            },
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Standard },
            },
          ],
        },
        {
          path: "subscriptions",
          component: BaseView,
          props: { klass: Subscription },
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: BaseShow,
              props: { klass: Subscription },
            },
          ],
        },
        {
          path: "installation",
          component: ImplementationAdministration,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "notice",
          component: ImplementationNotice,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "statistics",
          component: ImplementationStatistics,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "health_check",
          component: ImplementationHealthCheck,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "mail_server",
          component: ImplementationSmtp,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "document_settings",
          component: ImplementationDocuments,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "user_settings",
          component: ImplementationDefaultUserSettings,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "public_form",
          component: ImplementationPublicForm,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "background_jobs",
          component: BaseView,
          props: { klass: BackgroundJob },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "holidays/import",
          component: ListImport,
          props: { model: "holiday" },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "holidays",
          component: BaseView,
          props: { klass: Holiday },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "account",
          component: AccountSettings,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "preferences",
          component: SettingsEdit,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "devices",
          component: BaseView,
          props: { klass: Device },
          beforeEnter: ifAuthenticated,
        },
        {
          path: "about",
          component: AboutPage,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "license",
          component: LicensePage,
          beforeEnter: ifAuthenticated,
        },
        {
          path: "dashboard_screens",
          component: DashboardScreens,
          beforeEnter: ifAuthenticated,
          children: [
            {
              path: ":id",
              component: DashboardScreenView,
            },
          ],
        },
      ],
    },
    {
      path: "/public",
      component: PublicScreen,
      redirect: "/public/auth/login",
      children: [
        {
          path: "auth",
          component: LoginWrapper,
          redirect: "/public/auth/login",
          children: [
            {
              path: "login",
              component: LoginForm,
              beforeEnter: ifAadEnabled,
            },
            {
              path: "sso",
              component: SsoForm,
              beforeEnter: (to, from, next) => {
                if (store.state.app_state.auth) next("/");
                else if (store.state.basic_module.settings.aad) next();
                else next("/public/auth/login");
              },
            },
            {
              path: "recovery",
              component: PasswordRecoveryForm,
              beforeEnter: ifAadEnabled,
            },
            {
              path: "reset",
              component: PasswordResetForm,
              beforeEnter: ifAadEnabled,
            },
          ],
        },
        { path: "issues/new", component: PublicIssuesNew },
        {
          path: "issues/map",
          component: PublicIssuesMap,
          props: { klass: Issue },
        },
        { path: "issues/:id", component: PublicIssuesShow },
        { path: "documents/:id", component: PublicDocumentsShow },
      ],
    },
    { path: "/loading", component: LoadingScreen },
    // redirect old paths
    { path: "/login", redirect: "/public/auth/login" },
    { path: "/public/password/reset", redirect: "/public/auth/reset" },
    { path: "*", redirect: "/" },
  ],
  // set custom query resolver
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    var result = qs.stringify(query);

    return result ? "?" + result : "";
  },
});

export default router;
