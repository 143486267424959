import Vue from "vue";

const state = {
  devices: [],
  latest: [],
  alarms: [],
  loadingValues: false,
  loadingAlarms: false,
};

const mutations = {
  FETCH_DEVICES(state, data) {
    state.devices = data.objects;
  },
  FETCH_LATEST(state, data) {
    state.latest = data.latest_values;
  },
  FETCH_ALARMS(state, data) {
    state.alarms = data.latest_alarms;
  },
  RESET_STORE(state) {
    state.devices = [];
    state.latest = [];
    state.alarms = [];
  },
  loadingAlarms(state, data) {
    state.loadingAlarms = data;
  },
  loadingValues(state, data) {
    state.loadingValues = data;
  },
};

const getters = {
  get_devices: (state) => {
    return state.devices;
  },
  get_devices_latest_values: (state) => {
    return state.latest;
  },
  get_active_alarms: (state, getters, rootState) => {
    const diagnosticsId = rootState.basic_module.diagnostics.id;
    const devices = state.devices;
    if (devices.length && state.alarms.length) {
      return state.alarms
        .map((alarm, index) => {
          const asset = devices.find((device) => {
            const guid = device.external_guids?.find(
              (g) => g.connector_id === diagnosticsId
            );
            return guid && guid.guid === alarm.sensor;
          });
          if (asset) {
            return {
              index: index,
              id: asset.id,
              external_guids: asset.external_guids,
              name: asset.name,
              alarm: alarm,
            };
          }
        })
        .filter((item) => item && item.alarm.value)
        .sort((a, b) => a.name.localeCompare(b.name));
    }
  },
};

const actions = {
  fetchDevices(context, params) {
    Vue.http
      .get(`/assets`, {
        params: {
          filter: {
            parent_id: params.parent_id,
            category_id: params.category_ids,
          },
          all_fields: true,
        },
      })
      .then((response) => {
        context.commit("FETCH_DEVICES", {
          objects: response.body.objects,
        });
      });
  },
  fetchValues(context, params) {
    if (!state.loadingValues) {
      context.commit("loadingValues", true);
      Vue.http
        .get(`diagnostics/values/latest`, {
          params: {
            site: params.site,
          },
        })
        .then(
          (response) => {
            if (context.state.devices.length) {
              const mergedData = context.state.devices.map((item) => ({
                name: item.name,
                properties: item.properties,
                document_id: item.document_id,
                external_guids: item.external_guids,
                id: item.id,
                values: {
                  temperature: {},
                  current: {},
                  is_running: {},
                  leakage: {},
                  is_reversed: {},
                  engine_hours: {},
                },
              }));

              if (response.body.latest_values.length) {
                mergedData.forEach((item) => {
                  const guid = item.external_guids?.find(
                    (g) =>
                      g.connector_id ===
                      context.rootState.basic_module.diagnostics.id
                  );
                  if (guid) {
                    response.body.latest_values.forEach((obj) => {
                      if (obj.sensor === guid.guid) {
                        item.sensor = obj.sensor;
                        item.site = obj.site;
                        item.values[obj.field] = {
                          value: obj.value,
                          time: obj.time,
                        };
                        if (obj.field === "engine_hours") {
                          item.values[obj.field].value = Math.round(
                            item.values[obj.field].value
                          );
                        }
                      }
                    });
                  }
                });
                context.commit("FETCH_LATEST", {
                  latest_values: mergedData.sort((a, b) => {
                    const isRunningA = a.values.is_running.value;
                    const isRunningB = b.values.is_running.value;
                    const hasLeakageA = a.values.leakage.value;
                    const hasLeakageB = b.values.leakage.value;

                    if (isRunningA && !isRunningB) {
                      return -1; // prioritize running device
                    } else if (!isRunningA && isRunningB) {
                      return 1; // prioritize non-running device
                    } else if (!isRunningA && !isRunningB) {
                      return 0; // both are not running, keep their order unchanged
                    } else {
                      // both are running, sort based on leakage value
                      if (hasLeakageA && !hasLeakageB) {
                        return -1;
                      } else if (!hasLeakageA && hasLeakageB) {
                        return 1;
                      } else {
                        return 0;
                      }
                    }
                  }),
                });
              } else if (
                !context.state.latest.length &&
                !context.state.loadingValues
              ) {
                context.commit("FETCH_LATEST", {
                  latest_values: mergedData,
                });
              }
            }
            context.commit("loadingValues", false);
          },
          (response) => {
            // BasicMixin.methods.error_message("load", "latest", response);
            if (context.state.devices.length) {
              const mergedData = context.state.devices.map((item) => ({
                name: item.name,
                properties: item.properties,
                document_id: item.document_id,
                external_guids: item.external_guids,
                id: item.id,
                values: {
                  temperature: {},
                  current: {},
                  is_running: {},
                  leakage: {},
                  is_reversed: {},
                  engine_hours: {},
                },
              }));
              context.commit("FETCH_LATEST", {
                latest_values: mergedData,
              });
            }
            context.commit("loadingValues", false);
          }
        );
    }
  },
  fetchAlarms(context, params) {
    if (!state.loadingAlarms) {
      context.commit("loadingAlarms", true);
      Vue.http
        .get(`diagnostics/alarms/latest`, {
          params: {
            site: params.site,
          },
        })
        .then(
          (response) => {
            context.commit("FETCH_ALARMS", {
              latest_alarms: response.body.latest_alarms,
            });
            context.commit("loadingAlarms", false);
          },
          (response) => {
            // this.error_message("load", "alarms", response);
            context.commit("loadingAlarms", false);
          }
        );
    }
  },
  resetDiagnostics(context) {
    context.commit("RESET_STORE");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
