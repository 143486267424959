const state = {
  loaded: false,
  loaded_next: "/",
  loaded_next_query: {},
  message: {
    text: "Default message",
    type: "info",
    timeout: 5000,
    display: false,
  },
  permissions: {
    parts: [],
    create: [],
    private_notes: false,
  },
  settings: {
    public_form: {
      enabled: false,
      step_2: true,
      step_3: false,
    },
    brand: "AUSEMIO",
  },
  personalization: {},
  current_user: {},
  diagnostics: {},
  code_version: "",
  logo: "",
  impersonator: null,
};

const mutations = {
  change_message(state, message) {
    state.message.text = message.text;
    state.message.type = message.type;
    state.message.timeout = message.timeout;
    state.message.display = true;
  },
  set_email(state, email) {
    state.current_user.email = email;
  },
  set_name(state, name) {
    state.current_user.name = name;
  },
  set_personalization(state, personalization) {
    state.personalization = personalization;
  },
  set_otp_required_for_login(state, otp) {
    state.current_user.otp_required_for_login = otp;
  },
  setSettings(state, settings) {
    state.settings = settings;
  },
};

const getters = {
  get_current_user_avatar: (state) => {
    return {
      document_id: state.current_user.avatar_id,
      category_id: state.current_user.category_id,
    };
  },
  get_current_user_name: (state) => {
    return state.current_user.name;
  },
  get_current_user_email: (state) => {
    return state.current_user.email;
  },
  get_current_user_person_id: (state) => {
    return state.current_user.person_id;
  },
  get_current_user_eula_accepted_at: (state) => {
    return state.current_user.eula_accepted_at;
  },
  get_personalization: (state) => {
    return state.personalization;
  },
  get_diagnostics: (state) => {
    return state.diagnostics;
  },
  get_otp_required_for_login: (state) => {
    return state.current_user.otp_required_for_login;
  },
  is_impersonated: (state) => {
    return !!state.impersonator;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
