import Vue from "vue";
import Vuex from "vuex";
import BasicModule from "../../_generic/store/modules/basic_module";
import deleting from "../../_generic/store/modules/deleting";
import reloading from "../../_generic/store/modules/reloading";
import loadable from "../../_generic/store/modules/loadable";
import diagnostics from "../../_generic/store/modules/diagnostics";

import AppState from "../../_functions/store/modules/app_state";
import notifications from "../../_functions/store/modules/notifications";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    app_state: state.app_state,
  }),
});

export default new Vuex.Store({
  modules: {
    basic_module: BasicModule,
    deleting: deleting,
    app_state: AppState,
    loadable: loadable,
    notifications: notifications,
    reloading: reloading,
    diagnostics: diagnostics,
  },
  plugins: [vuexLocal.plugin],
});
